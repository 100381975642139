import React from "react"
import { Link } from "gatsby"

// assets
import navRight from "../assets/images/faq/navigation-right.svg"

const faqCard = ({ title, description, icon, slug }) => {
  // const loc = props.location

  return (
    <Link to={`/faq-inner/${slug}?1`}>
      <div className="faq-card">
        <div className="card-title-holder">
          <div className="card-title-flex">
            <img src={icon} className="card-icon" alt="icon" />
            <p className="card-title">{title}</p>
          </div>
          <img className="card-arrow" src={navRight} alt="navigation-icon" />
        </div>
      </div>
    </Link>
  )
}

export default faqCard
