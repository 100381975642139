import React, { useState } from "react"

// components
import Layout from "../components/layout"
import SEO from "../components/seo"
import FAQCard from "../components/faqCard"
import Banner from "../components/banner"
import SectionSearch from "../components/section-search"
import Cta from "../components/section-cta"

// json file
import faqs from "../data/faq.json"

// assets
import iconGeneral from "../assets/images/faq/icon-general.svg"
import iconAccount from "../assets/images/faq/icon-account.svg"
import iconSafety from "../assets/images/faq/icon-safety.svg"
import iconSeller from "../assets/images/faq/icon-seller.svg"
import iconBuyer from "../assets/images/faq/icon-buyer.svg"
import iconShipping from "../assets/images/faq/icon-shipping.svg"
import iconPayments from "../assets/images/faq/icon-payments.svg"
import iconSecurity from "../assets/images/faq/icon-security.svg"
import iconBusinessZone from "../assets/images/faq/icon-businesszone.svg"
import iconInHouse from "../assets/images/faq/icon-inhouse.svg"
import iconInApp from "../assets/images/faq/icon-inapp.svg"

const faqContent = [...faqs]

const FAQ = ({ location }) => {
  const [keyword, setKeyword] = useState("")

  const handleSearch = e => {
    setKeyword(e.target.value)
  }

  const dynamicSearch = () => {
    const filters = faqs.filter(names =>
      names.name.toLowerCase().includes(keyword.toLowerCase())
    )
    return filters
  }

  const handleClear = () => {
    setKeyword("")
  }

  return (
    <Layout className="faq bg-design vector-dash blue-footer">
      <SEO
        pageTitle="FAQ"
        title="FAQ | Servbees"
        description="Need info on how to use Servbees, manage your account, or fees? Find answers to these and more in our Frequently Asked Questions page."
      />
      <Banner
        title="Frequently Asked Questions"
        keyword={keyword}
        handleSearch={handleSearch}
        handleClear={handleClear}
      />
      <div className="faq-wrapper">
        {keyword !== "" ? (
          <SectionSearch
            content={dynamicSearch()}
            keyword={keyword}
            location={"/faq"}
          />
        ) : (
          <>
            <div className="faq-holder">
              <div className="cards-holder">
                {faqContent.map(faq => {
                  return (
                    <FAQCard
                      icon={
                        faq.name.includes("General")
                          ? iconGeneral
                          : faq.name.includes("Account")
                          ? iconAccount
                          : faq.name.includes("Safety")
                          ? iconSafety
                          : faq.name.includes("Seller")
                          ? iconSeller
                          : faq.name.includes("Buyer")
                          ? iconBuyer
                          : faq.name.includes("Shipping")
                          ? iconShipping
                          : faq.name.includes("Payments")
                          ? iconPayments
                          : faq.name.includes("Business")
                          ? iconBusinessZone
                          : faq.name.includes("In-App")
                          ? iconInApp
                          : faq.name.includes("In-House")
                          ? iconInHouse
                          : iconSecurity
                      }
                      title={faq.name}
                      description={faq.text}
                      slug={faq.slug}
                    />
                  )
                })}
              </div>
            </div>
          </>
        )}
      </div>
      <Cta />
    </Layout>
  )
}

export default FAQ
